import axios from 'axios';
import * as t from "./types";
import {
    EmailType,
    ExtractJobStatus,
    ILookupTableDetails,
    OrgType,
    TransactionType,
    UnitOfProcessing
} from './backend/extractions.types.generated';
import {
    parseOrganization
} from './type_parse';
import { createBackend } from './backend/combined_backend';
import { BUILD_NUMBER } from './commit';

// AUTH
export interface ISendMagicLinkRequest {
    email_address: string;
    remember_me: boolean;
}

export interface IRegisterRequest {
    email_address: string;
    password: string;
    first_name: string;
    last_name: string;
}

export interface IPasswordResetRequest {
    email_address: string;
}

export interface IAuthSourceRequest {
    email_address: string;
}

export interface ILoginRequest {
    email_address: string;
    password: string;
    remember_me: boolean;
}

export interface INewPasswordRequest {
    password: string;
}

export interface IChangePasswordRequest {
    old_password: string;
    new_password: string;
}

export interface ITransactionHistoryRequest {
    org_uuid: string;
    offset: number;
    limit: number;
}

export interface ISetUserDebugRequest {
    debug: boolean;
}

export interface IOrgRequest {
    org_uuid: string;
}

export interface IOrgUsersRequest {
    org_uuid: string;
    role?: t.OrgRole;
}

export interface IInsertSsoRequest {
    org_uuid: string;
    email_suffix: string;
    provider: "microsoft";
    tenant_id: string;
    client_id: string;
    client_secret: string;
}

export interface IGetSsoRequest {
    org_uuid: string;
}

export interface IGetSsoCallbackUrlRequest {
    org_uuid: string;
    provider: "microsoft";
}

export interface IDeleteSsoRequest {
    org_uuid: string;
}

export interface IInviteUserRequest {
    org_uuid: string;
    email: string;
}

export interface IAddUserToOrgRequest {
    org_uuid: string;
    user_uuid: string;
    role: t.OrgRole;
}

export interface IRemoveUserFromOrgRequest {
    org_uuid: string;
    user_uuid: string;
}

// ADMIN

export interface IUsersRequest {
    offset: number;
    limit: number;
    search?: string;
    role?: string;
}

export interface IUsersByUuidRequest {
    uuids: string[];
}

export interface IUserByUuidRequest {
    uuid: string;
}

export interface ISetUserEmailRequest {
    uuid: string;
    email: string;
}

export interface IUserRoleRequest {
    uuid: string;
    role: string;
}

export interface IUserOnboardingStepRequest {
    uuid: string;
    step: string;
}

export interface IAdminSetUserDebugRequest {
    uuid: string;
    debug: boolean;
}

export interface IUserStatsRequest {
    uuid: string;
}

export interface IAdminUserItemsRequest {
    user_uuid: string;
    offset: number;
    limit: number;
}

export interface IAdminUserItemRequest {
    user_uuid: string;
    item_uuid: string;
}

export interface IEmailUsersRequest {
    user_uuids: string[];
    email_type: EmailType;
}

export interface IAdminCreateOrgRequest {
    org_name: string;
    org_type: OrgType;
    admin_user_uuid: string;
}

export interface IAdminAddUserToOrgRequest {
    org_uuid: string;
    user_uuid: string;
    role: t.OrgRole;
}

export interface IAdminRemoveUserFromOrgRequest {
    org_uuid: string;
    user_uuid: string;
}

export interface IAdminTransactionHistoryRequest {
    org_uuid: string;
    offset: number;
    limit: number;
}

export interface IAddOrgCreditRequest {
    org_uuid: string;
    type: TransactionType;
    amount: number;
    details_text: string;
}

export interface IEditOrgCreditRequest {
    org_uuid: string;
    transaction_uuid: string;
    amount: number;
    details_text: string;
}

export interface IAdminOrgRequest {
    org_uuid: string;
}

export interface IAdminSetOrgTagFlagRequest {
    org_uuid: string;
    tag: string;
    flag: boolean;
}

export interface IRunBackgroundJobsRequest {
    job_name: "close_timeout_extract_jobs" | "forward_email_digests" | "validate_db_context_fields" | "validate_db_context_extract_params" | "validate_db_scrape_records" | "validate_db_endpoint_output_sheets" | "validate_db_endpoint_details";
}

export interface IDemoUsageRequest {
    offset: number;
    limit: number;
}

export interface IPromptLogRequest {
    offset: number;
    limit: number;
    job_uuid?: string;
}

export interface IConstAggrRequest {
}

// ONBOARDING
export interface IOnboardingUpdateUserDetailsRequest {
    details: t.IUserDetails;
}

export interface IOnboardingUpdateStepRequest {
    step: string;
}

// TEMPLATE SUGGEST

export interface ITemplateSuggestStartRequest {
    guidelines?: string;
    input_text?: string;
    input_file?: File;
}

export interface ITemplateSuggestGetRequest {
    job_uuid: string;
}

// SCRAPES

export interface IScrapeCheckStartRequest {
    template_uuid?: string;
    endpoint_uuid?: string;
    scrape_name: string;
    input_text?: string;
    input_files?: File[];
    input_files_selected_pages?: number[][];
    input_files_page_delimiters?: number[][];
    input_item_uuid?: string;
    // options
    store_extractions: boolean;
    unit_of_processing: UnitOfProcessing;
}

export interface IScrapeCheckGetRequest {
    job_uuid: string;
}

export interface IScrapeVerifyRequest {
    context_uuid: string;
    records: t.IRecord[];
}

// ITEMS
export interface IItemsGetRequest {
    offset: number;
    limit: number;
}

export interface IItemGetRequest {
    item_uuid: string;
}

export interface IItemDeleteRequest {
    item_uuid: string;
}

// ATTACHMENTS

export interface IExcelAttachmentRequest {
    uuid: string;
}

export interface IAttachmentRequest {
    uuid: string;
    filename: string;
    mimetype: string;
}

// DOWNLOAD

export interface IDownloadContextRequest {
    context_uuid: string;
}

// DEMO

export interface IDemoCheckRequest {
    fields: t.IContextField[];
    example_text: string;
}

export interface IConnectorLeadRequest {
    email_address: string;
}

// PROCESSING JOBS

export interface IExtractJobsRequest {
    offset: number;
    limit: number;
    endpoint_uuid?: string;
}

export interface IOrgExtractJobsRequest {
    org_uuid: string;
    offset: number;
    limit: number;
}

export interface IAdminStatusExtractJobsRequest {
    status: ExtractJobStatus;
    offset: number;
    limit: number;
}

export interface IExtractJobRequest {
    job_uuid: string;
}

export interface IOrgExtractJobRequest {
    org_uuid: string;
    job_uuid: string;
}

export interface IExtractJobStatusRequest {
    job_uuid: string;
}

export interface IAdminExtractJobsRequest {
    user_uuid?: string;
    org_uuid?: string;
    offset: number;
    limit: number;
}

export interface IAdminExtractJobRequest {
    user_uuid: string;
    job_uuid: string;
}

// AUDIT

export interface IUserAuditLogRequest {
    offset: number;
    limit: number;
}

export interface IOrgAuditLogRequest {
    org_uuid: string;
    offset: number;
    limit: number;
}

export interface IAdminUserAuditLogRequest {
    user_uuid: string;
    offset: number;
    limit: number;
}

export interface IAdminOrgAuditLogRequest {
    org_uuid: string;
    offset: number;
    limit: number;
}

export interface IAdminLogContextDefDownloadRequest {
    context_uuid: string;
}

// LOOKUP TABLES

export interface ILookupTableCreateRequest {
    org_uuid: string;
    name: string;
    headers: string[];
    details: ILookupTableDetails;
    sheet?: t.IExcelArraySheet;
}

export interface ILookupTableUpdateRequest {
    lookup_table_uuid: string;
    org_uuid: string;
    name: string;
    headers: string[];
    details: ILookupTableDetails;
}

export interface ILookupTablesGetRequest {
}

export interface ILookupTableGetRequest {
    lookup_table_uuid: string;
}

export interface ILookupTableDeleteRequest {
    lookup_table_uuid: string;
}

export interface ILookupTableUploadVersionRequest {
    lookup_table_uuid: string;
    sheet: t.IExcelArraySheet;
    update_type: t.LookupTableUpdateType;
}


////////////////////////////////////////////////////////////////////////////////////////////////////
// Initialize axios instance for calling the server

const api = axios.create({});
api.defaults.headers.common['x-client-commit-id'] = BUILD_NUMBER;

api.interceptors.response.use(
    (response) => {
        if (response.status === 302) {
            window.location.href = response.headers?.location || '/';
        }
        return response;
    },
    (error) => {
        const call_url: string = error.request.responseUrl;
        // when we are calling auth/login and get 401 then we entered wrong credentials
        // this is handled within /login screen so no need to redirect
        if (error.response && error.response.status === 401 && !call_url.endsWith("/auth/login")) {
            window.location.href = "/login";
        }
        // if we receive status 400, it means that we have validation error
        // compare the commit id from client and server
        if (error.response && error.response.status === 400) {
            const commit_id = error.response.headers["x-server-commit-id"] ?? "";
            const server_env = error.response.headers["x-server-env"] ?? "";
            if (commit_id !== BUILD_NUMBER && commit_id !== "") {
                // here we definitely know that the versions don't match
                // we need to hard-reload the page to get the new version
                if (server_env === "dev") {
                    console.error(`Client commit id: ${BUILD_NUMBER}, Server commit id: ${commit_id}`);
                    console.error("Server is in dev mode, skipping reload");
                } else {
                    window.location.reload();
                }
            }
        }
        // check if we have error message in response
        if (error.response && error.response.data && error.response.data.message) {
            return Promise.reject(new Error(error.response.data.message));
        }
        // no error message, return generic error
        return Promise.reject(error);
    }
)

////////////////////////////////////////////////////////////////////////////////////////////////////
// Prepare wrappers for backend endpoints

export const BackendObj = createBackend(api);

export class Backend {
    static async getHealth(): Promise<t.IHealth> {
        const { data } = await api.get(`/api/health`);
        return {
            timestamp: data.timestamp,
            now_date: data.now_date,
            last_heartbeat: data.last_heartbeat,
            build_date: data.build_date,
            uptime_seconds: data.uptime_seconds,
            commit_hash: data.commit_hash,
            db_version: data.db_version
        }
    }

    // AUTH

    static async sendMagicLink(req: ISendMagicLinkRequest): Promise<"ok" | "unknown_email" | "sso_email"> {
        const { data } = await api.post(`/auth/magic_link`, req);
        return data.status;
    }

    static async register(req: IRegisterRequest): Promise<{ status: "ok" | "sso" | "error", org_contacts?: { name: string, email: string }[] }> {
        const { data } = await api.post(`/auth/register`, req);
        return {
            status: data.status,
            org_contacts: data.org_contacts
        };
    }

    static async passwordReset(req: IPasswordResetRequest): Promise<void> {
        await api.post(`/auth/reset`, req);
    }

    static async authSource(req: IAuthSourceRequest): Promise<string> {
        const { data } = await api.post(`/auth/source`, req);
        return data.auth_source;
    }

    static async login(req: ILoginRequest): Promise<void> {
        await api.post(`/auth/login`, req);
    }

    static async newPassword(req: INewPasswordRequest): Promise<boolean> {
        const { data } = await api.post(`/auth/new_password`, req);
        return data.ok
    }

    static async changePassword(req: IChangePasswordRequest): Promise<boolean> {
        const { data } = await api.post(`/auth/change_password`, req);
        return data.ok
    }

    static async getUser(): Promise<{
        user?: t.IUser,
        memberships?: t.IMembership[],
        is_sso?: boolean,
        onboarding_status?: t.IOnboardingStatus,
        env: string
    }> {
        const { data } = await api.get(`/auth/user`);
        const user: t.IUser | undefined = data.user ? {
            uuid: data.user.uuid,
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            email: data.user.email,
            reset_password: data.user.reset_password,
            auth_source: data.user.auth_source,
            picture_url: data.user.picture_url,
            role: data.user.role,
            details: data.user.details,
            debug: data.user.debug,
            created_at: data.user.created_at
        } : undefined;
        const memberships: t.IMembership[] | undefined = data.memberships ?
            data.memberships.map((m: any): t.IMembership => ({
                org: parseOrganization(m.org),
                role: m.role,
            })) : undefined;
        const is_sso: boolean | undefined = data.is_sso;
        const env: string = data.env;
        const onboarding_status: t.IOnboardingStatus | undefined = data.onboarding_status ? {
            step: data.onboarding_status.step,
            start_ts: data.onboarding_status.start_ts,
            end_ts: data.onboarding_status.end_ts
        } : undefined;
        return { user, memberships, is_sso, onboarding_status, env };
    }

    static async logout(): Promise<void> {
        await api.get(`/auth/logout`);
    }

    static async getUserBalances(): Promise<t.IOrgBalance[]> {
        const { data } = await api.get(`/api/user_balances`);
        return data.balances.map((b: any): t.IOrgBalance => ({
            org: parseOrganization(b.org),
            balance: b.balance
        }));
    }

    static async getUserSubscriptions(): Promise<t.IOrgSubscription[]> {
        const { data } = await api.get(`/api/user_subscriptions`);
        return data.subscriptions.map((b: any): t.IOrgSubscription => ({
            org: parseOrganization(b.org),
            subscription: b.subscription
        }));
    }

    static async getOrg(req: IOrgRequest): Promise<{ org: t.IOrganization, users: t.IOrgUser[], balance: number }> {
        const { data } = await api.get(`/api/org?org_uuid=${req.org_uuid}`);
        return {
            org: parseOrganization(data.org),
            users: data.users.map((u: any): t.IOrgUser => ({
                uuid: u.uuid,
                first_name: u.first_name,
                last_name: u.last_name,
                email: u.email,
                picture_url: u.picture_url,
                role: u.role
            })),
            balance: data.balance
        };
    }

    static async getOrgTransactionHistory(req: ITransactionHistoryRequest): Promise<t.ITransactionList> {
        const { data } = await api.get(`/api/org_transaction_history?org_uuid=${req.org_uuid}&offset=${req.offset}&limit=${req.limit}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            transactions: data.transactions.map((t: any): t.ITransaction => ({
                uuid: t.uuid,
                user_uuid: t.user_uuid,
                org_uuid: t.org_uuid,
                type: t.type,
                amount: t.amount,
                created_at: t.created_at,
                details: t.details
            }))
        };
    }

    static async setUserDebug(req: ISetUserDebugRequest): Promise<void> {
        await api.post("/api/user_debug", { debug: req.debug ? "true" : "false" });
    }

    static async getOrgUsers(req: IOrgUsersRequest): Promise<t.IOrgUser[]> {
        const { data } = await api.get(`/api/org_users?org_uuid=${req.org_uuid}&role=${req.role}`);
        return data.users.map((u: any): t.IOrgUser => ({
            uuid: u.uuid,
            first_name: u.first_name,
            last_name: u.last_name,
            email: u.email,
            picture_url: u.picture_url,
            role: u.role
        }));
    }

    static async insertOrgSingleSignOn(req: IInsertSsoRequest): Promise<void> {
        await api.post(`/api/insert_sso`, req);
    }

    static async getOrgSingleSignOn(req: IGetSsoRequest): Promise<{ sso: t.IOrgSingleSignOn, callback_url: string }> {
        const { data } = await api.get(`/api/get_sso?org_uuid=${req.org_uuid}`);
        return { sso: data.sso, callback_url: data.callback_url };
    }

    static async getOrgSingleSignOnCallbackUrl(req: IGetSsoCallbackUrlRequest): Promise<string> {
        const { data } = await api.get(`/api/get_sso_callback_url?org_uuid=${req.org_uuid}&provider=${req.provider}`);
        return data.callback_url;
    }

    static async deleteOrgSingleSignOn(req: IDeleteSsoRequest): Promise<void> {
        await api.post(`/api/delete_sso`, req);
    }

    static async inviteUser(req: IInviteUserRequest): Promise<{ status: "added" | "sso" | "error", message?: string }> {
        const { data } = await api.post(`/api/invite_user`, req);
        return {
            status: data.status,
            message: data.message
        };
    }

    static async addUserToOrg(req: IAddUserToOrgRequest): Promise<void> {
        await api.post(`/api/add_user_to_org`, req);
    }

    static async removeUserFromOrg(req: IRemoveUserFromOrgRequest): Promise<void> {
        await api.post(`/api/remove_user_from_org`, req);
    }

    // ADMIN

    static async getUserList(req: IUsersRequest): Promise<t.IUserList> {
        let url = `/api/admin/users?offset=${req.offset}&limit=${req.limit}`;
        if (req.search) { url += `&search=${req.search}`; }
        if (req.role) { url += `&role=${req.role}`; }
        const { data } = await api.get(url);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            users: data.users.map((u: any): t.IUserWithStatus => ({
                uuid: u.uuid,
                first_name: u.first_name,
                last_name: u.last_name,
                email: u.email,
                auth_source: u.auth_source,
                picture_url: u.picture_url,
                role: u.role,
                reset_password: u.reset_password,
                details: u.details,
                debug: u.debug,
                created_at: u.created_at,
                onboarding_status: u.onboarding_status,
                org_balances: u.org_balances.map((b: any): t.IOrgBalance => ({
                    org: parseOrganization(b.org),
                    balance: b.balance
                }))
            }))
        };
    }

    static async getUsersByUuid(req: IUsersByUuidRequest): Promise<t.IUser[]> {
        const { data } = await api.post(`/api/admin/users_by_uuid`, req);
        return data.users.map((u: any): t.IUser => ({
            uuid: u.uuid,
            first_name: u.first_name,
            last_name: u.last_name,
            email: u.email,
            auth_source: u.auth_source,
            picture_url: u.picture_url,
            role: u.role,
            reset_password: u.reset_password,
            details: u.details,
            debug: u.debug,
            created_at: u.created_at
        }));
    }

    static async getUserByUuid(req: IUserByUuidRequest): Promise<{
        user?: t.IUser,
        memberships?: t.IMembership[],
        balances?: t.IOrgBalance[];
        onboarding_status?: t.IOnboardingStatus
        onboarding_events?: t.IOnboardingEvent[]
    }> {
        const { data } = await api.get(`/api/admin/user?uuid=${req.uuid}`);
        const user: t.IUser | undefined = data.user ? {
            uuid: data.user.uuid,
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            email: data.user.email,
            reset_password: data.user.reset_password,
            auth_source: data.user.auth_source,
            picture_url: data.user.picture_url,
            role: data.user.role,
            details: data.user.details,
            debug: data.user.debug,
            created_at: data.user.created_at
        } : undefined;
        const memberships: t.IMembership[] | undefined = data.memberships ?
            data.memberships.map((m: any): t.IMembership => ({
                org: parseOrganization(m.org),
                role: m.role,
            })) : undefined;
        const balances: t.IOrgBalance[] | undefined = data.balances ?
            data.balances.map((b: any): t.IOrgBalance => ({
                org: parseOrganization(b.org),
                balance: b.balance
            })) : undefined;
        const onboarding_status: t.IOnboardingStatus | undefined = data.onboarding_status ? {
            step: data.onboarding_status.step,
            start_ts: data.onboarding_status.start_ts,
            end_ts: data.onboarding_status.end
        } : undefined;
        const onboarding_events: t.IOnboardingEvent[] | undefined = data.onboarding_events ?
            data.onboarding_events.map((e: any): t.IOnboardingEvent => ({
                event: e.event,
                ts: e.ts
            })) : undefined;
        return { user, memberships, balances, onboarding_status, onboarding_events };
    }

    static async setUserEmail(req: ISetUserEmailRequest): Promise<void> {
        await api.post(`/api/admin/user_email`, req);
    }

    static async setUserRole(req: IUserRoleRequest): Promise<void> {
        await api.post(`/api/admin/user_role`, req);
    }

    static async setUserOnboardingStep(req: IUserOnboardingStepRequest): Promise<void> {
        await api.post(`/api/admin/user_onboarding_step`, req);
    }

    static async setAdminUserDebug(req: IAdminSetUserDebugRequest): Promise<void> {
        await api.get(`/api/admin/user_debug?uuid=${req.uuid}&debug=${req.debug ? "true" : "false"}`);
    }

    static async getUserStats(req: IUserStatsRequest): Promise<t.IUserStats> {
        const { data } = await api.get(`/api/admin/user_stats?uuid=${req.uuid}`);
        const stats = data.stats;
        return {
            scrape_count: stats.scrape_count,
            item_count: stats.item_count,
            context_count: stats.context_count,
            endpoint_count: stats.endpoint_count,
            extract_jobs_7_days: stats.extract_jobs_7_days.map((e: any): t.IUserExtractJobStat => ({
                extract_job_type: e.extract_job_type,
                count: e.count
            })),
            extract_jobs_30_days: stats.extract_jobs_30_days.map((e: any): t.IUserExtractJobStat => ({
                extract_job_type: e.extract_job_type,
                count: e.count
            }))
        };
    }

    static async getAdminUserItems(req: IAdminUserItemsRequest): Promise<t.IItemList> {
        const { data } = await api.get(`/api/admin/user_items?user_uuid=${req.user_uuid}&offset=${req.offset}&limit=${req.limit}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            items: data.items
        };
    }

    static async getAdminUserItem(req: IAdminUserItemRequest): Promise<t.IItem | undefined> {
        const { data } = await api.get(`/api/admin/user_item?user_uuid=${req.user_uuid}&item_uuid=${req.item_uuid}`);
        return data.item;
    }

    static async emailUsers(req: IEmailUsersRequest): Promise<void> {
        await api.post(`/api/admin/email_users`, req);
    }

    static async createOrg(req: IAdminCreateOrgRequest): Promise<t.IOrganization> {
        const { data } = await api.get(`/api/admin/create_org?org_name=${req.org_name}&org_type=${req.org_type}&admin_user_uuid=${req.admin_user_uuid}`);
        return parseOrganization(data.org);
    }

    static async adminAddUserToOrg(req: IAdminAddUserToOrgRequest): Promise<void> {
        await api.get(`/api/admin/add_user_to_org?org_uuid=${req.org_uuid}&user_uuid=${req.user_uuid}&role=${req.role}`);
    }

    static async adminRemoveUserFromOrg(req: IAdminRemoveUserFromOrgRequest): Promise<void> {
        await api.get(`/api/admin/remove_user_from_org?org_uuid=${req.org_uuid}&user_uuid=${req.user_uuid}`);
    }

    static async getAdminOrgTransactionHistory(req: IAdminTransactionHistoryRequest): Promise<t.ITransactionList> {
        const { data } = await api.get(`/api/admin/org_transaction_history?org_uuid=${req.org_uuid}&offset=${req.offset}&limit=${req.limit}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            transactions: data.transactions.map((t: any): t.ITransaction => ({
                uuid: t.uuid,
                user_uuid: t.user_uuid,
                org_uuid: t.org_uuid,
                type: t.type,
                amount: t.amount,
                created_at: t.created_at,
                details: t.details
            }))
        };
    }

    static async addOrgCredit(req: IAddOrgCreditRequest): Promise<void> {
        await api.get(`/api/admin/add_org_credit?org_uuid=${req.org_uuid}&type=${req.type}&amount=${req.amount}&details_text=${req.details_text}`);
    }

    static async editOrgCredit(req: IEditOrgCreditRequest): Promise<void> {
        await api.post(`/api/admin/edit_org_credit`, req);
    }

    static async getAdminOrg(req: IAdminOrgRequest): Promise<{ org: t.IOrganization, users: t.IOrgUser[], balance: number, sso?: t.IOrgSingleSignOn }> {
        const { data } = await api.get(`/api/admin/org?org_uuid=${req.org_uuid}`);
        return {
            org: parseOrganization(data.org),
            users: data.users.map((u: any): t.IOrgUser => ({
                uuid: u.uuid,
                first_name: u.first_name,
                last_name: u.last_name,
                email: u.email,
                picture_url: u.picture_url,
                role: u.role
            })),
            balance: data.balance,
            sso: data.sso ? {
                org_uuid: data.sso.org_uuid,
                user_uuid: data.sso.user_uuid,
                email_suffix: data.sso.email_suffix,
                provider: data.sso.provider,
                tenant_id: data.sso.tenant_id,
                client_id: data.sso.client_id,
                client_secret: data.sso.client_secret,
                created_at: data.sso.created_at
            } : undefined
        };
    }

    static async setOrganizationTagFlag(req: IAdminSetOrgTagFlagRequest): Promise<void> {
        await api.post(`/api/admin/set_org_tag_flag`, req);
    }

    static async runBackgroundJobs(req: IRunBackgroundJobsRequest): Promise<void> {
        await api.post(`/api/admin/run_background_jobs`, req);
    }

    static async getDemoUsage(req: IDemoUsageRequest): Promise<t.IDebugDemoUsageList> {
        const { data } = await api.get(`/api/admin/demo_usage?offset=${req.offset}&limit=${req.limit}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            usages: data.usages.map((u: any): t.IDebugDemoUsage => ({
                fields: u.fields,
                example_text: u.example_text,
                example_records: u.example_records,
                visitor_details: u.visitor_details,
                user_uuid: u.user_uuid,
                created_at: u.created_at
            }))
        };
    }

    static async getPromptLogs(req: IPromptLogRequest): Promise<t.IDebugPromptLogList> {
        const { data } = await api.get(`/api/admin/prompt_log?offset=${req.offset}&limit=${req.limit}${req.job_uuid !== undefined ? `&job_uuid=${req.job_uuid}` : ""}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            logs: data.logs.map((l: any): t.IDebugPromptLog => ({
                user_uuid: l.user_uuid,
                user_email: l.user_email,
                model: l.model,
                num_input_tokens: l.num_input_tokens,
                num_output_tokens: l.num_output_tokens,
                duration_ms: l.duration_ms,
                task_type: l.task_type,
                input: l.input,
                output: l.output,
                created_at: l.created_at
            }))
        };
    }

    static async getCostAggr(req: IConstAggrRequest): Promise<t.ICostAggr> {
        const { data } = await api.get(`/api/admin/cost_aggr`);

        return {
            from_ts: data.aggr.from_ts,
            to_ts: data.aggr.to_ts,
            job_costs: {
                cost_per_call: data.aggr.job_costs.cost_per_call,
                type_cost_per_call: data.aggr.job_costs.type_cost_per_call,
                model_cost_per_call: data.aggr.job_costs.model_cost_per_call,
                model_type_cost_per_call: data.aggr.job_costs.model_type_cost_per_call
            }
        };
    }

    // ONBOARDING

    static async updateUserDetails(req: IOnboardingUpdateUserDetailsRequest): Promise<void> {
        await api.post(`/api/onboarding/update_user_details`, req);
    }

    static async updateOnboardingStep(req: IOnboardingUpdateStepRequest): Promise<t.IOnboardingStatus> {
        const { data } = await api.get(`/api/onboarding/update_step?step=${req.step}`);
        return {
            step: data.step,
            start_ts: data.start_ts,
            end_ts: data.end_ts
        };
    }

    // CONTEXT SUGGEST

    static async suggestTemplateStart(req: ITemplateSuggestStartRequest): Promise<string> {
        // we might have a file so we send it over as a form data
        const { input_text, input_file, guidelines } = req;
        const form_data = new FormData();
        if (input_text) { form_data.append("input_text", input_text); }
        if (input_file) { form_data.append("input_file", input_file); }
        if (guidelines) { form_data.append("guidelines", guidelines); }
        const { data } = await api.post(`/api/template/suggest_start`, form_data, { headers: { "Content-Type": "multipart/form-data" } });
        return data.job_uuid;
    }

    static async suggestTemplateGet(req: ITemplateSuggestGetRequest): Promise<t.ISuggestTemplateResult> {
        const { data } = await api.post(`/api/template/suggest_get`, req);
        return {
            status: data.status,
            template: data.template,
            extraction_time_ts: data.extraction_time_ts,
            message: data.message
        };
    }

    // SCRAPES

    static async extractScrapeStart(req: IScrapeCheckStartRequest): Promise<string> {
        // we might have a file so we send it over as a form data
        const {
            template_uuid, endpoint_uuid, scrape_name, input_text, input_files, input_files_selected_pages,
            input_files_page_delimiters, input_item_uuid, store_extractions, unit_of_processing
        } = req;
        const form_data = new FormData();
        form_data.append("scrape_name", scrape_name);
        if (template_uuid) { form_data.append("template_uuid", template_uuid); }
        if (endpoint_uuid) { form_data.append("endpoint_uuid", endpoint_uuid); }
        if (input_text) { form_data.append("input_text", input_text); }
        if (input_files) {
            for (const input_file of input_files) {
                form_data.append("input_file", input_file);
            }
        }
        if (input_files_selected_pages) {
            form_data.append("input_files_selected_pages", JSON.stringify(input_files_selected_pages));
        }
        if (input_files_page_delimiters) {
            form_data.append("input_files_page_delimiters", JSON.stringify(input_files_page_delimiters));
        }
        if (input_item_uuid !== undefined) {
            form_data.append("input_item_uuid", input_item_uuid);
        }
        form_data.append("store_extractions", store_extractions ? "true" : "false");
        form_data.append("unit_of_processing", unit_of_processing);
        const { data } = await api.post(`/api/scrape/check_start`, form_data, { headers: { "Content-Type": "multipart/form-data" } });
        return data.job_uuid;
    }

    static async extractScrapeGet(req: IScrapeCheckGetRequest): Promise<t.IScrapeCheck> {
        const { data } = await api.post(`/api/scrape/check_get`, req);

        return {
            status: data.status,
            template: data.template,
            endpoint: data.endpoint,
            items: data.items,
            confirmations: data.confirmations,
            extraction_time_ts: data.extraction_time_ts,
            message: data.message
        };
    }

    static async verifyScrape(req: IScrapeVerifyRequest): Promise<t.IRecord[]> {
        const { data } = await api.post(`/api/scrape/verify`, req);
        return data.verified_records;
    }

    // ITEMS

    static async getItems(req: IItemsGetRequest): Promise<t.IItemSlim[]> {
        const { data } = await api.get(`/api/items/get?offset=${req.offset}&limit=${req.limit}`);
        return data.items;
    }

    static async getItem(req: IItemGetRequest): Promise<{ item?: t.IItem, message?: string }> {
        const { data } = await api.get(`/api/item/get?item_uuid=${req.item_uuid}`);
        const { item, message } = data;

        return {
            item: item ? {
                uuid: item.uuid,
                name: item.name,
                documents: item.documents,
                details: item.details,
                endpoint_uuid: item.endpoint_uuid,
                template_uuid: item.template_uuid,
                scrapes: item.scrapes,
                extract_confirmations_uuid: item.extract_confirmations_uuid,
                extract_confirmations_status: item.extract_confirmations_status,
                created_at: item.created_at,
                endpoint: item.endpoint,
                template: item.template,
                attachments: item.attachments,
                digest: item.digest,
                extract_confirmation_log: item.extract_confirmation_log
            } : undefined,
            message
        }
    }

    static async deleteItem(req: IItemDeleteRequest): Promise<void> {
        await api.get(`/api/item/delete?item_uuid=${req.item_uuid}`);
    }

    // ATTACHMENTS

    static async getExcelAttachment(req: IExcelAttachmentRequest): Promise<t.IExcelArraySheet[]> {
        const { data } = await api.get(`/api/attachment/excel?uuid=${req.uuid}`);
        return data.sheets;
    }

    static async getAttachment(req: IAttachmentRequest): Promise<File> {
        const response = await api.get(`/api/attachment/get?uuid=${req.uuid}`, { responseType: 'blob' });
        const file = new File([response.data], req.filename, { type: req.mimetype });
        return file;
    }

    // DEMO

    static async checkDemo(req: IDemoCheckRequest): Promise<t.IContextDemoCheck> {
        const { data } = await api.post(`/api/demo/check`, req);
        return {
            records: data.example_records
        };
    }

    static async connectorLead(req: IConnectorLeadRequest): Promise<void> {
        await api.get(`/api/connector/lead?email_address=${req.email_address}`);
    }

    // PROCESSING JOBS

    static async getExtractJobs(req: IExtractJobsRequest): Promise<t.IExtractJobList> {
        const { data } = req.endpoint_uuid ?
            await api.get(`/api/extract_jobs?endpoint_uuid=${req.endpoint_uuid}&offset=${req.offset}&limit=${req.limit}`) :
            await api.get(`/api/extract_jobs?offset=${req.offset}&limit=${req.limit}`);
        return this.parseExtractJobs(data);
    }

    static async getOrgExtractJobs(req: IOrgExtractJobsRequest): Promise<t.IExtractJobList> {
        const { data } = await api.get(`/api/org_extract_jobs?org_uuid=${req.org_uuid}&offset=${req.offset}&limit=${req.limit}`);
        return this.parseExtractJobs(data);
    }

    static async getExtractJob(req: IExtractJobRequest): Promise<t.IExtractJobWithEvents> {
        const { data } = await api.get(`/api/extract_job?job_uuid=${req.job_uuid}`);
        return this.parseExtractJob(data);
    }

    static async getOrgExtractJob(req: IOrgExtractJobRequest): Promise<t.IExtractJobWithEvents> {
        const { data } = await api.get(`/api/org_extract_job?org_uuid=${req.org_uuid}&job_uuid=${req.job_uuid}`);
        return this.parseExtractJob(data);
    }

    static async getExtractJobStatus(req: IExtractJobStatusRequest): Promise<string> {
        const { data } = await api.get(`/api/extract_job_status?job_uuid=${req.job_uuid}`);
        return data.status;
    }

    static async getAdminExtractJobs(req: IAdminExtractJobsRequest): Promise<t.IExtractJobList> {
        const { data } = req.user_uuid ?
            await api.get(`/api/admin/extract_jobs?user_uuid=${req.user_uuid}&offset=${req.offset}&limit=${req.limit}`) :
            await api.get(`/api/admin/extract_jobs?org_uuid=${req.org_uuid}&offset=${req.offset}&limit=${req.limit}`);
        return this.parseExtractJobs(data);
    }

    static async getAdminStatusExtractJobs(req: IAdminStatusExtractJobsRequest): Promise<t.IExtractJobList> {
        const { data } = await api.get(`/api/admin/status_extract_jobs?status=${req.status}&offset=${req.offset}&limit=${req.limit}`);
        return this.parseExtractJobs(data);
    }

    static async getAdminExtractJob(req: IAdminExtractJobRequest): Promise<t.IExtractJobWithEvents> {
        const { data } = await api.get(`/api/admin/extract_job?user_uuid=${req.user_uuid}&job_uuid=${req.job_uuid}`);
        return this.parseExtractJob(data);
    }

    static async getExtractJobReport(): Promise<t.IExtractJobReport[]> {
        const { data } = await api.get(`/api/admin/extract_job_report`);
        return data.reports.map((r: any): t.IExtractJobReport => ({
            ts: r.ts,
            type: r.type,
            context_name: r.context_name,
            item_name: r.item_name
        }));
    }

    // AUDIT

    static async getUserAuditLog(req: IUserAuditLogRequest): Promise<t.IUserAuditList> {
        const { data } = await api.get(`/api/user_audit_log?offset=${req.offset}&limit=${req.limit}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            audits: data.audits.map((a: any): t.IUserAudit => ({
                user_uuid: a.user_uuid,
                type: a.type,
                details: a.details,
                created_at: a.created_at
            }))
        };
    }

    static async getOrgAuditLog(req: IOrgAuditLogRequest): Promise<t.IOrgAuditList> {
        const { data } = await api.get(`/api/org_audit_log?org_uuid=${req.org_uuid}&offset=${req.offset}&limit=${req.limit}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            audits: data.audits.map((a: any): t.IOrgAudit => ({
                org_uuid: a.org_uuid,
                type: a.type,
                details: a.details,
                created_at: a.created_at
            }))
        };
    }


    static async getAdminUserAuditLog(req: IAdminUserAuditLogRequest): Promise<t.IUserAuditList> {
        const { data } = await api.get(`/api/admin/user_audit_log?user_uuid=${req.user_uuid}&offset=${req.offset}&limit=${req.limit}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            audits: data.audits.map((a: any): t.IUserAudit => ({
                user_uuid: a.user_uuid,
                type: a.type,
                details: a.details,
                created_at: a.created_at
            }))
        };
    }

    static async getAdminOrgAuditLog(req: IAdminOrgAuditLogRequest): Promise<t.IOrgAuditList> {
        const { data } = await api.get(`/api/admin/org_audit_log?org_uuid=${req.org_uuid}&offset=${req.offset}&limit=${req.limit}`);
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            audits: data.audits.map((a: any): t.IOrgAudit => ({
                org_uuid: a.org_uuid,
                type: a.type,
                details: a.details,
                created_at: a.created_at
            }))
        };
    }

    static async logContextDefDownload(req: IAdminLogContextDefDownloadRequest): Promise<void> {
        await api.get(`/api/admin/log_context_def_download?context_uuid=${req.context_uuid}`);
    }

    static parseExtractJobs(data: any): t.IExtractJobList {
        return {
            offset: data.offset,
            limit: data.limit,
            total: data.total,
            jobs: data.jobs.map((j: any): t.IExtractJobSimple => ({
                uuid: j.uuid,
                user_uuid: j.user_uuid,
                org_uuid: j.org_uuid,
                type: j.type,
                status: j.status,
                start_ts: j.start_ts,
                end_ts: j.end_ts,
                error: j.error === true,
                endpoint_uuid: j.endpoint_uuid,
                message: j.message
            }))
        };
    }

    static async createLookupTable(req: ILookupTableCreateRequest): Promise<{ lookup_table_uuid: string }> {
        const { data } = await api.post(`/api/lookup_table/create`, req);

        return { lookup_table_uuid: data.lookup_table_uuid };
    }

    static async updateLookupTable(req: ILookupTableUpdateRequest): Promise<void> {
        await api.post(`/api/lookup_table/update`, req);
    }

    static async getLookupTables(): Promise<t.ILookupTableBase[]> {
        const { data } = await api.get(`/api/lookup_tables/get`);

        return data.lookup_tables.map((lt: any): t.ILookupTableBase => ({
            uuid: lt.uuid,
            org_uuid: lt.org_uuid,
            name: lt.name,
            headers: lt.headers,
            active_version_uuid: lt.active_version_uuid,
            active_version: lt.active_version ? {
                uuid: lt.active_version.uuid,
                lookup_table_uuid: lt.active_version.lookup_table_uuid,
                no_of_rows: lt.active_version.no_of_rows,
                created_at: lt.active_version.created_at,
                sheet: lt.active_version.sheet
            } : undefined,
            details: lt.details,
            created_at: lt.created_at
        }));
    }

    static async getLookupTable(req: ILookupTableGetRequest): Promise<t.ILookupTable | undefined> {
        const { data } = await api.get(`/api/lookup_table/get?lookup_table_uuid=${req.lookup_table_uuid}`);

        if (!data.lookup_table) {
            return undefined;
        }

        return {
            uuid: data.lookup_table.uuid,
            org_uuid: data.lookup_table.org_uuid,
            name: data.lookup_table.name,
            headers: data.lookup_table.headers,
            details: data.lookup_table.details,
            active_version_uuid: data.lookup_table.active_version_uuid,
            created_at: data.lookup_table.created,
            email_address: data.lookup_table.email_address,
            versions: data.lookup_table.versions.map((v: any): t.ILookupTableVersion => ({
                uuid: v.uuid,
                lookup_table_uuid: v.lookup_table_uuid,
                no_of_rows: v.no_of_rows,
                created_at: v.created_at,
                sheet: v.sheet
            })),
            active_version: data.lookup_table.active_version ? {
                uuid: data.lookup_table.active_version.uuid,
                lookup_table_uuid: data.lookup_table.active_version.lookup_table_uuid,
                no_of_rows: data.lookup_table.active_version.no_of_rows,
                created_at: data.lookup_table.active_version.created_at,
                sheet: data.lookup_table.active_version.sheet
            } : undefined
        };
    }

    static async deleteLookupTable(req: ILookupTableDeleteRequest): Promise<void> {
        await api.get(`/api/lookup_table/delete?lookup_table_uuid=${req.lookup_table_uuid}`);
    }

    static async uploadLookupTableVersion(req: ILookupTableUploadVersionRequest): Promise<void> {
        await api.post(`/api/lookup_table/upload_version`, req);
    }

    static parseExtractJob(data: any): t.IExtractJobWithEvents {
        return {
            job: {
                uuid: data.job.uuid,
                user_uuid: data.job.user_uuid,
                org_uuid: data.job.org_uuid,
                type: data.job.type,
                status: data.job.status,
                start_ts: data.job.start_ts,
                end_ts: data.job.end_ts,
                error: data.job.error === true,
                endpoint_uuid: data.job.endpoint_uuid,
                message: data.job.message
            },
            events: data.events.map((e: any): t.IExtractJobEventSimple => ({
                uuid: e.uuid,
                job_uuid: e.job_uuid,
                type: e.type,
                status: e.status,
                ts: e.ts,
                message: e.message
            }))
        };
    }

    // CLEAN TEXT

    static async getCleanTextFromOcr(form_data: FormData): Promise<string> {
        const { data } = await api.post(`/api/clean_text/ocr`, form_data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return data.clean_text;
    }

    static async getCleanTextFromExcel(form_data: FormData): Promise<string> {
        const { data } = await api.post(`/api/clean_text/excel`, form_data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return data.clean_text;
    }

    static async getArrayFromExcel(form_data: FormData): Promise<t.IExcelArraySheet[]> {
        const { data } = await api.post(`/api/lookup_table/excel`, form_data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return data.sheets.map((s: any): t.IExcelArraySheet => ({ name: s.name, data: s.data }));
    }

}
