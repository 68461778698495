import {
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import * as hi from "@heroicons/react/24/outline";

import * as t from "../lib/types";
import { BackendObj } from "../lib/backend";
import {
    classNames,
    prettyDateTime,
    redirectToExternalPage
} from "../lib/utils";
import { selectIsSidebarLarge } from "../lib/scraper.slice";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { EmptyItemList } from "./Items";

type DownloadProps = {
    cols_lg: number;
    skip_empty_list: boolean;
    max_items?: number;
    onDownload?: () => void;
}

export function Download(props: DownloadProps) {
    const { cols_lg, skip_empty_list, max_items, onDownload } = props;

    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    const [download_info, setDownloadInfo] = useState<t.ITemplateDownloadInfo[] | undefined>(undefined);

    useEffect(() => {
        BackendObj.extractions.listTemplatesForDownload({}).then(({ templates }) => {
            if (max_items !== undefined) {
                // we need to sort by last extraction timestamp and limit the number of items
                templates.sort((a, b) => b.last_extraction_ts - a.last_extraction_ts);
                templates = templates.slice(0, max_items);
            }
            setDownloadInfo(templates);
        });
    }, [max_items]);

    const download_excel = (template_uuid: string) => {
        redirectToExternalPage(`/api/download/template?template_uuid=${template_uuid}&type=excel`, true);
        // wait for the download to complete
        setTimeout(() => { onDownload && onDownload(); }, 5000);
    };

    const download_json = (template_uuid: string) => {
        redirectToExternalPage(`/api/download/template?template_uuid=${template_uuid}&type=json`, true);
        // wait for the download to complete
        setTimeout(() => { onDownload && onDownload(); }, 5000);
    };

    if (download_info === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (download_info.length === 0 && !skip_empty_list) {
        return <EmptyItemList />;
    }

    return <ul className={`grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-${cols_lg} max-w-6xl`}>
        {download_info.map((info, idx) => (
            <li key={idx} className="col-span-1 divide-y divide-gray-200 rounded-lg shadow bg-zinc-100 border-gray-200 border">
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium text-gray-900">{info.template_name}</h3>
                        </div>
                        <div className="flex flex-row mt-1">
                            <div className="flex-1 truncate text-xs text-gray-500">
                                {info.no_extractions} {info.no_extractions !== 1 ? "extractions" : "extraction"}
                            </div>
                            <div className="flex truncate text-xs text-gray-500 text-right">{prettyDateTime(info.last_extraction_ts)}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="flex w-0 flex-1">
                            <button
                                onClick={() => download_excel(info.template_uuid)}
                                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:bg-zinc-100">
                                <hi.TableCellsIcon className="h-5 w-5 text-sky-300" aria-hidden="true" />
                                Excel
                            </button>
                        </div>
                        <div className="-ml-px flex w-0 flex-1">
                            <button
                                onClick={() => download_json(info.template_uuid)}
                                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 hover:bg-zinc-100">
                                <span className="text-sky-300">{"{ : }"}</span>
                                JSON
                            </button>
                        </div>
                    </div>
                </div>
            </li>
        ))}
    </ul>;
}

export function DownloadWrapper() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto m-10", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="pb-10">
            <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-3xl sm:tracking-tight">
                Download extractions
            </h2>
        </div>
        <Download cols_lg={3} skip_empty_list={false} />
    </div>;
}
