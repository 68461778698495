import { useSelector } from "react-redux";
import { classNames } from "../lib/utils";
import { selectUser } from "../lib/scraper.slice";
import { USER_ROLES } from "../lib/consts";

export interface ITab {
    name: string;
    key: string;
    hide?: boolean;
    only_admin?: boolean;
};

type TabsProps = {
    tabs: ITab[];
    selected_tab_key: string;
    setSelectedTab: (key: string) => void;
};

export function Tabs(props: TabsProps) {
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const { tabs, selected_tab_key, setSelectedTab } = props;

    const show_tabs = tabs.filter(tab => !tab.only_admin || is_admin);

    return <div className="border-b border-gray-200">
        <nav className="-mb-px flex flex-wrap space-x-4" aria-label="Tabs">
            {show_tabs.map((tab) => (
                <span
                    key={tab.key}
                    className={classNames(
                        tab.key === selected_tab_key
                            ? "border-sky-500 text-sky-600 bg-sky-50"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-2 px-4 text-sm font-medium cursor-pointer"
                    )}
                    aria-current={tab.key === selected_tab_key ? "page" : undefined}
                    onClick={() => setSelectedTab(tab.key)}
                >
                    {tab.name}
                </span>
            ))}
        </nav>
    </div>;
}

export function CompactTabs(props: TabsProps) {
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const { tabs, selected_tab_key, setSelectedTab } = props;

    const show_tabs = tabs
        .filter(tab => !tab.only_admin || is_admin)
        .filter(tab => !tab.hide);

    return <div className="border-b border-gray-200 ">
        <nav className="-mb-px flex flex-wrap space-x-1 outer-div" aria-label="Tabs">
            {show_tabs.map((tab) => (
                <span
                    key={tab.key}
                    className={classNames(
                        tab.key === selected_tab_key
                            ? "border-sky-500 text-sky-600 bg-sky-50"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 py-1 px-4 text-sm font-medium cursor-pointer",
                        "truncate max-w-[200px]"
                    )}
                    aria-current={tab.key === selected_tab_key ? "page" : undefined}
                    onClick={() => setSelectedTab(tab.key)}
                >
                    {tab.name}
                </span>
            ))}
        </nav>
    </div>;
}