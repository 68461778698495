import React, { useState, useEffect } from 'react';
import { LoadingSpinner } from '../components/LoadingSpinner';

import { BackendObj } from "../lib/backend";
import {
    IAuditLogEntry
} from '../lib/backend/extractions.types.generated';
import { prettyDateTime } from "../lib/utils";
import { FullScreenText } from '../components/FullScreen';
import { ErrorMessageBar } from '../components/ErrorMessageBar';

export type AuditLogEntity = {
    uuid: string;
    type: "template" | "context" | "endpoint";
}

interface AuditLogHistoryProps {
    entities: AuditLogEntity[];
}

const AuditLogHistory: React.FC<AuditLogHistoryProps> = ({ entities }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [auditLogEntries, setAuditLogEntries] = useState<IAuditLogEntry[]>([]);
    const [show_details, setShowDetails] = useState<boolean>(false);
    const [details, setDetails] = useState<string>("");

    useEffect(() => {
        const fetchAuditLog = async () => {
            if (entities.length === 0) {
                return;
            }
            try {
                setLoading(true);
                const data: IAuditLogEntry[] = [];
                // load data for all entities
                for (const entity of entities) {
                    const response = await BackendObj.extractions.getAuditLogForEntity({
                        entity_uuid: entity.uuid,
                        entity_type: entity.type
                    });
                    data.push(...response.entries);
                }
                data.sort((a, b) => a.timestamp - b.timestamp); // sort by timestamp descending
                setAuditLogEntries(data);
                setError(null);
            } catch (err: any) {
                setError('Failed to fetch audit log entries, ' + err.meessage);
                console.error('Error fetching audit log:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchAuditLog();
    }, [entities]);

    const getDiffString = (entry: IAuditLogEntry): any => {
        if (entry.diff_string !== undefined) {
            // replace console color instructions with actual colors
            const colorMap: { [key: string]: string } = {
                '0': '#000000', // Black (reset)
                '31': '#aa0000', // Red
                '32': '#00aa00', // Green
                '33': '#aaaa00', // Yellow
                '34': '#0000aa', // Blue
                '35': '#FF00FF', // Magenta
                '36': '#00FFFF', // Cyan
                '37': '#FFFFFF', // White
                '90': '#808080', // Bright Black (Gray)
                '91': '#FF8080', // Bright Red
            };

            const diff_string = entry.diff_string
                .replace(/\x1b\[(\d+)m/g, (match, p1) => { // eslint-disable-line
                    return colorMap[p1] ? `<span style="color: ${colorMap[p1]}">` : '';
                })
                .replace(/\x1b\[0m/g, '</span>'); // eslint-disable-line
            return <pre dangerouslySetInnerHTML={{ __html: diff_string }} />;
        }
        return null;
    };

    const showRecordData = (record: any) => {
        setDetails(JSON.stringify(record, null, 2));
        setShowDetails(true);
        return true;
    };

    return (
        <div className="min-w-full">
            {loading && <LoadingSpinner />}
            {error && <ErrorMessageBar message={error} clearMessage={() => setError(null)} />}
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Timestamp &amp; Type &amp; UUID</th>
                        <th scope="col" className="px-3 py-2 text-left text-sm font-semibold text-gray-900">Changes</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {auditLogEntries.map((entry) => (
                        <tr key={entry.id}>
                            <td className="px-3 py-2 text-sm text-gray-500 align-top">
                                {prettyDateTime(entry.timestamp)} <br />
                                {entry.entity_type} <br />
                                {entry.entity_uuid} <br />
                                <button
                                    className="text-xs text-gray-500 hover:text-gray-900 px-1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        showRecordData(entry.old_data ?? {});
                                    }}>Old data</button>
                                <button
                                    className="text-xs text-gray-500 hover:text-gray-900 px-1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        showRecordData(entry.new_data ?? {});
                                    }}>New data</button>
                            </td>
                            <td className="px-3 py-2 text-sm text-gray-500 align-top">
                                {getDiffString(entry)}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {show_details && <FullScreenText
                title={""}
                text={details}
                show={show_details}
                onClose={() => setShowDetails(false)} />}
        </div>
    );
};

export default AuditLogHistory;

