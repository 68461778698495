import {
    Fragment
} from "react"

import {
    Listbox,
    Transition
} from "@headlessui/react"
import {
    CheckIcon,
    ChevronUpDownIcon
} from "@heroicons/react/20/solid"

import { IOrganization } from "../lib/types";
import {
    classNames,
    prettySmartDateTime
} from "../lib/utils";
import { OrgPill } from "./OrgPill";

type Value = {
    id: string;
    name: string;
    org?: IOrganization;
    ts?: number;
    icon: any;
}

type RichDropdownProps = {
    values: Value[];
    selected: string;
    onChange: (id: string) => void;
}

export function RichDropdown(props: RichDropdownProps) {
    const { values, selected, onChange } = props;

    const selected_value = values.find((value) => value.id === selected);

    return (
        <Listbox value={selected} onChange={onChange}>
            {({ open }) => (
                <Fragment>
                    <div className="relative">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                                {selected_value && <selected_value.icon className="text-sky-500 h-5 w-5 flex-shrink-0 rounded-full" />}
                                {selected_value && <span className="ml-3 block truncate">{selected_value.name}</span>}
                                {selected_value && selected_value.org && <span className="ml-3"><OrgPill name={selected_value.org.name} type={selected_value.org.type} small={true} /></span>}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="outer-div absolute z-10 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {values.map((value, idx) => (
                                    <Listbox.Option
                                        key={idx}
                                        className={({ active }) =>
                                            classNames(
                                                active ? "bg-sky-600 text-white" : "text-gray-900",
                                                "relative cursor-default select-none py-2 pl-3 pr-9"
                                            )
                                        }
                                        value={value.id}
                                    >
                                        {({ selected, active }) => (
                                            <Fragment>
                                                <div className="flex items-center">
                                                    <value.icon className="text-sky-500 h-5 w-5 flex-shrink-0 rounded-full" />
                                                    <span className={classNames(selected ? "font-semibold" : "font-normal", "ml-3 block truncate")}>{value.name}</span>
                                                    {value && value.org && <span className="ml-3"><OrgPill name={value.org.name} type={value.org.type} small={true} /></span>}
                                                    {value && value.ts && <span className="flex-grow text-right pr-6 text-xs text-gray-400">{prettySmartDateTime(value.ts)}</span>}
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? "text-white" : "text-sky-600",
                                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </Fragment>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Fragment>
            )}
        </Listbox>
    )
}
